*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style-type: none;
  text-decoration-line: none;
 
}

body,html{
  height: 100%;
  overflow: hidden;
}
/* @media screen and (max-width: 450px) {
  body,html{
    height: 100%;
    overflow: auto;
  }
} */
@font-face {
  font-family: 'fontspring-extralight';

src: local('MyFont'), url(./assets/Fontspring-DEMO-altivo-extralight.otf) format('truetype');
}
@font-face {
  font-family: 'fontspring-medium';

  src: local('MyFont'), url(./assets/Fontspring-DEMO-altivo-medium.otf) format('truetype');
}

@font-face {
  font-family: 'Lato-Regular';
  src: local('MyFont'), url(./assets/Lato-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Lato-Thin';
  src: local('MyFont'), url(./assets/Lato-Thin.ttf) format('truetype');
}
@font-face {
  font-family: 'fontspring-regular';
  src: local('MyFont'), url(./assets/altivo-regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Lato-Hairline';
  src: local('MyFont'), url(./assets/Lato-Hairline-6.ttf) format('truetype');
}

@font-face {
  font-family: 'ApproachMonoTRIAL-Md';
  src: url('./assets/ApproachMonoTRIAL-Md.otf');
}
@font-face {
  font-family: 'ApproachMonoTRIAL-Lt';
  src: url('./assets/ApproachMonoTRIAL-Lt.otf');
}
@font-face {
  font-family: 'ApproachMonoTRIAL-Rg';
  src: url('./assets/ApproachMonoTRIAL-Rg.otf');
}

@font-face {
  font-family: 'ApproachMonoTRIAL-Bd';
  src: url('./assets/ApproachMonoTRIAL-Bd.otf');
}

@font-face {
  font-family: 'PingFang SC Semibold';
  src: url('./assets/PingFang\ SC\ Semibold.ttf');
}


#root{
  height: 100%;
}
.App{
height: 100%;
}

@font-face {
  font-family: 'Galyon-Regular';
  src: url('./assets/Galyon-Regular.otf');
}

@font-face {
  font-family: 'Galyon-Book';
  src: url('./assets/Galyon-Book.otf');
}
@font-face {
  font-family: 'Galyon-Bold';
  src: url('./assets/Galyon-Bold.otf');
}

@font-face {
  font-family: 'Roboto-Medium';
  src: url('./assets/Roboto-Medium.ttf');
}

@font-face {
  font-family: 'Roboto-Bold';
  src: url('./assets/Roboto-Bold.ttf');
}

@font-face {
  font-family: 'Roboto-Thin';
  src: url('./assets/Roboto-Thin.ttf');
}

@font-face {
  font-family: 'Roboto-Light';
  src: url('./assets/Roboto-Light.ttf');
}

@font-face {
  font-family: 'Roboto-Black';
  src: url('./assets/Roboto-Black.ttf');
}

@font-face {
  font-family: 'Roboto-Regular';
  src: url('./assets/Roboto-Regular.ttf');
}